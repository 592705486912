import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import { Loading } from 'components/Loading';
import { URLS } from 'routes';

const DemandGroupDetails = Loadable({
  loader: () => import('containers/DemandGroups/Details'),
  loading: Loading,
});

const SearchCriteria = Loadable({
  loader: () => import('containers/Demands/DemandDetails/SearchCriteria'),
  loading: Loading,
});

const CandidatesReview = Loadable({
  loader: () => import('containers/Demands/DemandDetails/LongList/CandidatesReview'),
  loading: Loading,
});

const DemandsList = Loadable({
  loader: () => import('containers/Demands/DemandsList'),
  loading: Loading,
});

const ActivityLogs = Loadable({
  loader: () => import('containers/Demands/Activity/Logs'),
  loading: Loading,
});

const CreateDemand = Loadable({
  loader: () => import('../Demands/CreateDemandGroup'),
  loading: Loading,
});

const Stats = Loadable({
  loader: () => import('containers/Stats'),
  loading: Loading,
});

const Router = () => {
  return (
    <Switch>
      <Route path={URLS.DEMANDS.DETAILS.DISCOVER.route} component={SearchCriteria} exact />
      <Route path={URLS.DEMANDS.DETAILS.LONG_LIST.route} component={CandidatesReview} exact />
      <Route path={URLS.DEMAND_GROUPS.DETAILS.route} component={DemandGroupDetails} exact />
      <Route path={URLS.DEMANDS.ACTIVITY.LOGS.route} component={ActivityLogs} exact />
      <Route path={URLS.DEMANDS.STATS.route} component={Stats} exact />
      <Route path={URLS.DEMANDS.INDEX.route} component={DemandsList} exact />
      <Route path={URLS.DEMANDS.CREATE.route} component={CreateDemand} exact />
      <Redirect from={URLS.DEMANDS.DETAILS.DETAILS.route} to={URLS.DEMANDS.DETAILS.DISCOVER.route} />
      <Route component={() => <Redirect to={URLS.DEMANDS.INDEX.route} />} />
    </Switch>
  );
};

export default Router;
