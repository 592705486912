import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './index.less';

export default function Progress() {
  const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="progress">
      <Spin indicator={icon} />
    </div>
  );
}
