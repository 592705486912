import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';

import * as serviceWorker from 'serviceWorker';

import 'styles/less/main/main.less';
import ReactApp from 'ReactApp';

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: process.env.REACT_APP_SENTRY_ENVIRONMENT });
}

ReactDOM.render(<ReactApp />, document.getElementById('root'));

serviceWorker.unregister();
