import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAuth0 } from '@auth0/auth0-react';

import { URLS } from 'routes';
import Router from './Router';
import LoginButton from 'components/LoginButton';
import LogoutButton from 'components/LogoutButton';

const { Header, Footer, Content } = Layout;

function App(props) {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const pathName = props.location.pathname;
  const selectedKeys = [];
  if (pathName === URLS.DEMANDS.CREATE.route) {
    selectedKeys.push(URLS.DEMANDS.CREATE.route);
  }
  if (pathName === URLS.DEMANDS.INDEX.route) {
    selectedKeys.push(URLS.DEMANDS.INDEX.route);
  }
  if (pathName === URLS.DEMANDS.STATS.route) {
    selectedKeys.push(URLS.DEMANDS.STATS.route);
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <LoginButton />
      </div>
    );
  }
  if (!user.authorization.roles.includes('demand_guru_user')) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        Access denied. Ask your admins for help.
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Demand Guru</title>
      </Helmet>
      <Layout className="layout">
        <Header style={{ background: '#fff' }}>
          <Menu theme="light" mode="horizontal" selectedKeys={selectedKeys}>
            <Menu.Item key={URLS.ROOT.route}>
              <Link to={URLS.ROOT.route}>
                <img
                  src="/favicon.ico"
                  alt="Favicon"
                  style={{
                    height: '36px',
                    width: '36px',
                    position: 'relative',
                    bottom: '5px',
                  }}
                />
                <span style={{ paddingLeft: '15px', fontSize: '1.5em' }}>Demand Guru</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={URLS.DEMANDS.INDEX.route}>
              <Link to={URLS.DEMANDS.INDEX.route}>Demands</Link>
            </Menu.Item>
            <Menu.Item key={URLS.DEMANDS.CREATE.route}>
              <Link to={URLS.DEMANDS.CREATE.route}>Create Demand</Link>
            </Menu.Item>
            <Menu.Item key={URLS.DEMANDS.STATS.route}>
              <Link to={URLS.DEMANDS.STATS.route}>Stats</Link>
            </Menu.Item>
            <Menu.Item key="logout">
              <LogoutButton />
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ padding: '0 50px', background: '#fff', minHeight: '800px' }}>
          <Router />
        </Content>
        <Footer style={{ textAlign: 'center', background: '#fff' }}>© {new Date().getFullYear()} Scoutbee GmbH</Footer>
      </Layout>
    </>
  );
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(App);
