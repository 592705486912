import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'antd';

import Progress from './Progress';

export const Loading = ({ isLoading, error }) => {
  if (isLoading) {
    return <Progress />;
  } else if (error) {
    return <Alert type={'error'} showIcon message={'Unable not load requested page. Please try again later'} />;
  } else {
    return null;
  }
};

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};
