import url from 'utils/routes';

export const BASE_URL = '';
export const BASE_API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/v2`;

export const URLS = {
  ROOT: url(''),
  DEMAND_GROUPS: {
    DETAILS: url('/demand-group/:demandGroupId'),
  },
  DEMANDS: {
    INDEX: url('/demands'),
    CREATE: url('/demands/create'),
    STATS: url('/stats'),
    DETAILS: {
      DETAILS: url('/demands/:demandId/'),
      DISCOVER: url('/demands/:demandId/discover'),
      LONG_LIST: url('/demands/:demandId/long-list'),
    },
    ACTIVITY: {
      LOGS: url('/demands/:demandId/activity'),
    },
  },
};

export const SERVER_URLS = {
  V2: {
    GEO: {
      COUNTRIES_BY_REGION: url('/geo/countries_by_region'),
    },
    ACTIVITY: {
      LOGS: url('/demands/:pk/activity'),
      RAW_LOGS: url('/demands/:pk/activity-raw'),
    },
    STATS: {
      GURU: url('/guru-statistics'),
      RELIABILITY: {
        RANGE: url('/reliability-score-range'),
        GROUPS: url('/reliability-score-groups'),
      },
    },
    DEMAND_GROUPS: {
      DETAILS: url('/demand-group/:pk'),
      CREATE: url('/demand-group/create'),
      UPDATE: url('/demand-group/:pk/update'),
      APPROVE: url('/demand-group/:pk/approve'),
      REJECT: url('/demand-group/:pk/reject'),
    },
    DEMANDS: {
      LIST: url('/demands'),
      PUSH_TO_DDP: url('/demands/:demand_id/push_ddp'),
      UPDATE_DDP_MATCHING: url('/demands/:pk/update-ddp-matching'),
      SYNC_SEARCH_PHRASES_TRANSLATION: url('/demands/:uuid/sync-translate-search-phrases'),
      ADD_SINGLE_LANGUAGE_PHRASES_TRANSLATION: url('/demands/:uuid/single-language-phrases-translate'),
      GET_INITIAL_SEARCH_PHRASES: url('/demands/:uuid/get-initial-search-phrases'),
      DETAILS: url('/demands/:pk'),
      CREATE: url('/demands/create'),
      UPDATE: url('/demands/:uuid/update'),
      DELETE: url('/demands/:uuid/delete'),
      RENAME: url('/demands/:uuid/rename'),
      PROCESS: url('/demands/:id/process'),
      ABORT: url('/demands/:id/abort'),
      DUPLICATE: url('/demands/:id/duplicate'),
      CLONE: url('/demands/:uuid/clone'),
    },
    CANDIDATES: {
      CREATE: url('/demands/:uuid/create_candidate'),
      LIST: url('/demands/:pk/candidates'),
      REVIEW: url('/demands/:pk/candidates_review'),
      UPDATE: url('/candidate/:pk/update'),
    },
  },
};

export const STREAMLINE_URLS = {
  LOGOUT_PAGE: url('/logout'),
};

export const API_URLS = {
  V2: {
    ACTIVITY: {
      RAW_LOGS: url(BASE_API_URL + '/demands/:pk/activity-raw'),
    },
    DEMANDS: {
      XLS: url(BASE_API_URL + '/demands/:pk/xls'),
      IMPORT_SCORES: url(BASE_API_URL + '/demands/:pk/import-scores'),
    },
  },
};
