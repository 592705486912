import React from 'react';
import { Router } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserHistory } from 'history';
import { Auth0Provider } from '@auth0/auth0-react';

import App from 'containers/App';

export default function ReactApp() {
  const history = createBrowserHistory();

  const queryClient = new QueryClient();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}>
      <Router history={history}>
        <BrowserRouter>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </HelmetProvider>
        </BrowserRouter>
      </Router>
    </Auth0Provider>
  );
}
